// Roboto
@font-face {
  font-family: Roboto-Thin;
  font-style: normal;
  font-weight: 100;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Thin.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-ThinItalic;
  font-style: normal;
  font-weight: 100;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-ThinItalic.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Light;
  font-style: normal;
  font-weight: 300;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Light.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-LightItalic;
  font-style: normal;
  font-weight: 300;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-LightItalic.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Regular.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Italic;
  font-style: normal;
  font-weight: 400;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Italic.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Medium.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-MediumItalic;
  font-style: normal;
  font-weight: 500;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-MediumItalic.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-Bold.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-BoldItalic;
  font-style: normal;
  font-weight: 700;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-BoldItalic.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-Black;
  font-style: normal;
  font-weight: 900;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-black.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Roboto-BlackItalic;
  font-style: normal;
  font-weight: 900;
  src: url("/app/presentation/assets/fonts/Roboto/Roboto-BlackItalic.ttf");
  unicode-range: U+000-5FF;
}

// Poppins
@font-face {
  font-family: Poppins-ExtraLight;
  font-style: normal;
  font-weight: 200;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-ExtraLight.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-Light;
  font-style: normal;
  font-weight: 300;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-Light.ttf");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: 300;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-Regular.woff")
    format("woff");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-Medium;
  font-style: normal;
  font-weight: 300;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-Medium.woff")
    format("woff");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: 500;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-SemiBold.woff")
    format("woff");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-Bold;
  font-style: normal;
  font-weight: 600;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-Bold.woff")
    format("woff");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: Poppins-BlackItalic;
  font-style: normal;
  font-weight: 600;
  src: url("/app/presentation/assets/fonts/Poppins/Poppins-BlackItalic.ttf");
  unicode-range: U+000-5FF;
}

// MO Icons
@font-face {
  font-family: MOIcon;
  font-style: normal;
  font-weight: normal;
  src: url("/app/presentation/assets/fonts/MOIcon/moicon.eot");
  src: url("/app/presentation/assets/fonts/MOIcon/moicon.eot#iefix")
      format("embedded-opentype"),
    url("/app/presentation/assets/fonts/MOIcon/moicon.ttf") format("truetype"),
    url("/app/presentation/assets/fonts/MOIcon/moicon.woff") format("woff"),
    url("/app/presentation/assets/fonts/MOIcon/moicon.svg#icomoon")
      format("svg");
}

// MO New Icons Solid
@font-face {
  font-family: Unicons-solid;
  font-style: normal;
  font-weight: normal;
  src: url("/app/presentation/assets/fonts/Icons/unicons-solid.ttf");
}

// MO New Icons line
@font-face {
  font-family: Unicons-line;
  font-style: normal;
  font-weight: normal;
  src: url("/app/presentation/assets/fonts/Icons/unicons-line.ttf");
}

// Variables New Fonts
// Poppins
$poppins-regular: Poppins-Regular, sans-serif;
$poppins-light: Poppins-Light, sans-serif;
$poppins-medium: Poppins-Medium, sans-serif;
$poppins-semibold: Poppins-SemiBold, sans-serif;
$poppins-bold: Poppins-Bold, sans-serif;
$poppins-blackitalic: Poppins-BlackItalic, sans-serif;

// Roboto
$roboto-thin: Roboto-Thin, sans-serif;
$roboto-thinitalic: Roboto-ThinItalic, sans-serif;
$roboto-light: Roboto-Light, sans-serif;
$roboto-lightitalic: Roboto-LightItalic, sans-serif;
$roboto-regular: Roboto-Regular, sans-serif;
$roboto-italic: Roboto-Italic, sans-serif;
$roboto-medium: Roboto-Medium, sans-serif;
$roboto-mediumitalic: Roboto-MediumItalic, sans-serif;
$roboto-bold: Roboto-Bold, sans-serif;
$roboto-bolditalic: Roboto-BoldItalic, sans-serif;
$roboto-black: Roboto-Black, sans-serif;
$roboto-blackitalic: Roboto-BlackItalic, sans-serif;
/*
*
*
*
*
*
*
*
*
*
*
*
*/

$line-height: 1.5em;
$line-height-mobile: 1.1em;

$font-family-icon: MOIcon, sans-serif;
$font-family-title: Poppins-Regular, sans-serif;
$font-family-light: Poppins-Light, sans-serif;
$font-family-regular: Poppins-Regular, sans-serif;
$font-family-medium: Poppins-Medium, sans-serif;
$font-family-semibold: Poppins-SemiBold, sans-serif;
$font-family-bold: Poppins-Bold, sans-serif;
$font-family-blackitalic: Poppins-BlackItalic, sans-serif;

$h1-font-size: 1.8rem;
$h2-font-size: 1.6rem;
$h3-font-size: 1.4rem;
$h4-font-size: 1.2rem;
$h5-font-size: 1.1rem;
$h6-font-size: 1rem;

$p-font-size: 0.9rem;
$label-font-size: 0.8rem;
$icon-font-size: 1.3rem;

$small-font-size: 0.75rem;
