.center {
  margin: 0 auto;
}

.hidden {
  display: none;
}

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bottom {
  @include to(tablet-l) {
    // position: fixed;
    // top: calc(100% - 7rem);
    left: 0;
    padding: 1.5rem 1rem;
    width: 100%;
    background-color: #fff;
    box-shadow: inset 2px 10px 30px 0 rgba(169, 171, 187, 0.22);
    align-items: stretch !important;
    z-index: 99;
    margin-top: 0 !important;

    button.btn-large {
      min-width: 10rem;
      flex: 0 50%;
      font-size: 0.8rem;
    }
  }
}

.p-l-1 {
  padding-left: 1rem;
}

.p-l-2 {
  padding-left: 2rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-r-1 {
  margin-right: 1rem;
}

.m-b-1 {
  margin-bottom: 1rem;
}
.m-b-2 {
  margin-bottom: 2rem;
}
.m-t-1 {
  margin-top: 1rem;
}
.m-t-2 {
  margin-top: 2rem;
}

@media (min-width: 990px) {
  .hidden-lg {
    display: none;
  }
}
@media (max-width: 990px) {
  .hidden-md {
    display: none;
  }
}

.td-center {
  display: flex;
  justify-content: center;
}

.relative-box {
  position: relative;
}

.layout-title {
  h3 {
    font-family: $poppins-bold;
    color: $neutral-80;
    font-size: 28px;
    span {
      color: $main-coral-60;
    }
  }
}

.layout-shadow-card {
  background: white;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
  border-radius: 10px;
  padding: 20px;
}

.box-premium {
  padding: 10px;
  background-color: $main-indigo-10;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  border: 2px solid $main-indigo-20;
  border-radius: 5px;
  i {
    height: 31px;
    font-size: 33px;
    color: $main-indigo-60;
  }
  p {
    font-size: 14px;
    font-family: $poppins-medium;
    margin: 0;
    padding: 0;
    line-height: 30px;
    span {
      color: $main-indigo-60;
    }
  }
  .control {
    margin-top: 5px;
  }
}

.box-content-bottom-forms {
  max-width: 70%;
}

.cursor-pointer * {
  cursor: pointer;
}

.text-start {
  text-align: start !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

ul {
  &.no-margin {
    margin: 0 30px;
    padding: 0;
  }
}
