// textarea
textarea {
  background-color: $neutral-20;
  border-color: $color-gray-5;
  border-radius: 0.375rem;
  color: $neutral-80;
  font-family: $roboto-medium;
  font-size: 14px;
  line-height: 22px;
  padding: 0.875rem 0 0 1rem;
  resize: none;
  width: 100%;
  min-height: 117px;
}

span {
  &.note {
    font-family: $roboto-regular;
    font-size: 12px;
    line-height: 18px;
    color: $neutral-50;
    opacity: 0.8;
  }
}

.form-group {
  position: relative;
  label {
    color: $color-gray-2;
  }
}

.form-control {
  @include inputControlBase;
}

*:not(button):focus {
  // border-color: inherit;
  border-color: $color-gray-2;
  outline: none;
  box-shadow: none;

  &::placeholder {
    color: $neutral-60;
  }
}

*::placeholder {
  color: $neutral-50;
  font-size: 0.8rem;
}

select.form-control {
  background: url(/assets/img/icon/down-arrow-common.png) no-repeat 95% 50%;
  background-size: 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

button:focus {
  outline: 1px dotted;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.input__password {
  text-security: disc;
  -webkit-text-security: disc;
  -mox-text-security: disc;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

$color-checkbox: $color-common;
$color-checkbox-checked: $color-active;
$size-checkbox: 20px;
$size-checked: 12px;

.checkbox {
  display: none;
}

.checkbox + label {
  cursor: pointer;
  font-size: 0.85rem;
  line-height: 1.5em;
  padding-left: 23px;
  position: relative;
}

.checkbox + label:before {
  border: 2px solid $color-checkbox;
  content: "";
  cursor: pointer;
  height: $size-checkbox;
  left: 0;
  position: absolute;
  top: -0.2rem;
  width: $size-checkbox;

  @include from(laptop) {
    top: 50%;
    transform: translateY(-50%);
  }
}
.checkbox:focus + label:before {
  box-shadow: 0 0 2px 1px $color-common;
}
.checkbox + label {
  margin-bottom: 0.5rem;
  p {
    padding: 0;
  }
}

.checkbox.radio + label:before {
  border-radius: 50%;
}

.input-error {
  color: $color-alert;
  font-size: 0.9rem;
}

.checkbox:disabled + label {
  color: $color-gray-3;
  cursor: not-allowed;
  &:before {
    cursor: not-allowed;
    border: 1px solid $color-gray-3;
  }
}

.checkbox:checked + label:before {
  // border-color: $color-checkbox-checked;
}
.checkbox:checked + label:after {
  content: "";
  background: $color-checkbox-checked;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 4px;
  width: $size-checked;
  height: $size-checked;

  @include from(laptop) {
    top: 50%;
    transform: translateY(-50%);
  }
}

.checkbox.white + label,
.checkbox.white + label > a {
  color: white;
}

.checkbox.white + label:before {
  border-color: white;
}

.checkbox.white:checked + label:after {
  background-color: white;
}

.checkbox + label.error::before {
  border-color: $color-alert;
}

.text-error {
  color: $color-alert;
  font-family: $font-family-medium;
}

[type="radio"] {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    color: $neutral-80;
    font-size: 14px;
    font-family: $poppins-medium;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    padding-left: 28px;
    position: relative;
    white-space: nowrap;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #fff;
  }
  &:checked + label:before {
    border: 5px solid $caribbean-green-60;
  }
  &:not(:checked) + label:before {
    border: 2px solid $neutral-40;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: $neutral;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:disabled {
    + label:before {
      border: 5px solid $neutral-30;
      cursor: no-allowed;
    }
    + label:after {
      background: $neutral;
      cursor: no-allowed;
    }
    &:checked {
      + label:before {
        border: 5px solid $neutral-30;
        cursor: no-allowed;
      }
      + label:after {
        background: $neutral;
        cursor: no-allowed;
      }
    }
  }
}

/**
* FORM ERRORS
**/
form.submited .form-control.ng-invalid,
form.submited .ng-invalid[formcontrolname]:not(.form) > .form-control,
form.submited
  .ng-invalid[formcontrolname]:not(.form)
  .select-group
  > .form-control,
form.submited
  .ng-invalid[formcontrolname]:not(.form)
  .form-group
  > .form-relative
  > .form-control,
form.submited .form-suffix input.ng-invalid {
  color: $color-alert;
  border: 1px solid $color-alert;
}
form.submited .checkbox.ng-invalid + label::before {
  border-color: $color-alert;
}

.form-checkbox {
  :ng-deep {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important;
    }
    .mat-checkbox-mixedmark {
      background-color: red;
    }
    .mat-checkbox-ripple {
      .mat-ripple-element {
        background: red !important;
      }
    }

    .mat-checkbox-checked {
      .mat-checkbox-background {
        background: red;
      }
      .mat-checkbox-ripple {
        .mat-ripple-element {
          background: red !important;
        }
      }
    }
  }
}

// TODO move to component MO forms, when create date picker
.mo-filter-date {
  @include inputControlBase;

  padding: 5px;
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: transparent;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: transparent;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    font-size: 16px;
    color: $neutral-70;
    position: absolute;
    top: -30px;
    right: -6px;
  }
  .mat-form-field {
    padding-top: 8px;
  }
  .mat-date-range-input-start-wrapper {
    min-width: 36px;
  }
}

.mo-input-address {
  display: block;
  margin-top: 22px;
  margin-bottom: 33px;
  position: relative;
  .form-control {
    @include inputControlBase;
    padding: 10px 14px;
    height: 46px;
    font-size: 13px;
  }
}

.mo-filters-forms-design {
  max-width: 270px;
  margin-right: 8px;
  @include inputControlBase();
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: transparent;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 2px;
    margin-top: -50px;
    position: relative;
    margin-left: -8px;
  }
  .mat-select-value-text {
    color: $neutral-80;
    font-family: $roboto-regular;
  }
}

.custom-select {
  .mat-select-panel {
    border-radius: 6px;
    margin-top: 48px;
  }
}

.custom {
  .mat-pseudo-checkbox-checked {
    background-color: $color-primary !important;
  }
  .mat-pseudo-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
  .mat-pseudo-checkbox-checked::after {
    top: 4.4px;
    left: 3px;
  }
  .mat-pseudo-checkbox {
    color: $neutral-40;
  }
}

.messagge-info-container {
  background: white;
  height: 100%;
  position: absolute;
  top: 72px;
  width: calc(100vw - 7rem);
  padding-top: 40px;
  left: 0;
  z-index: 44;
}

label {
  &.mo-label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
    font-family: $roboto-regular;
    padding-left: 5px;
    &.is-floating-label {
      position: absolute;
      top: -11px;
      left: 16px;
      font-size: 12px;
      z-index: 1;
    }
  }
}

.bg-block-fomrs {
  display: block;
  margin-top: 22px;
  margin-bottom: 33px;
}
