*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

html,
body {
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 15px;
}

body {
  color: $color-common;
  font-family: $font-family-regular;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: $line-height;

  @media (max-width: map-get($map: $breakpoints, $key: mobile-l)) {
    line-height: $line-height-mobile;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
  font-family: $font-family-bold;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}
small {
  font-size: $small-font-size;
}

p,
a {
  font-size: $p-font-size;
  font-family: $font-family-regular;
}
label {
  font-size: $label-font-size;
}
.label-title {
  font-family: $font-family-medium;
  color: $color-gray-3;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: $label-font-size;
  text-align: left;
}

p,
a {
  padding: calc($padding / 2) 0 $padding;
}

b {
  font-family: $font-family-bold;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: $color-default;
  color: $color-default-contrast;
  border: 1px solid;
  cursor: pointer;
  font-family: $font-family-bold;
  font-size: $small-font-size;
  transition: all 0.3s ease;
  outline: 0;

  &:active {
    transform: scale(0.96);
  }
}

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.title-page {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    color: $color-primary;
    font-family: $font-family-bold;
    margin-left: 0.5rem;
  }
}

.title-page.title-description {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  align-items: flex-start;
  .title-name {
    display: flex;
    align-items: center;
  }
  .title-description {
    text-align: right;
    padding: 0;
  }
}

.backdrop {
  position: fixed;
  z-index: 4; /** Este estará por encima de todos los componentes de la página hasta del menú **/
  top: 0;
  left: 0;
  background-color: rgba($color: $color-gray-6, $alpha: 0.2);
  height: 100vh;
  width: 100vw;
  bottom: 0;
  right: 0;
  visibility: visible;
  opacity: 1;

  transition: visibility 0.5s linear 0.5s, opacity 1s linear 0.5s;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.submenu-float {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 100%;
  background: white;
  opacity: 1;
  color: $color-common;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: map-get($z-index, menu-options);
  flex-direction: column;
  justify-content: space-between;
  display: none;

  &-item {
    display: block;
    padding: 0.5rem 1rem;
    font-size: $p-font-size;
    line-height: $line-height;
  }
}

.block-box {
  display: block;
}

.ng-tooltip {
  background: $aditional-blue-100;
  box-shadow: 0px -4px 17px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 26px 16px 16px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $neutral;
  position: absolute;
  z-index: 1000;
  max-width: 150px;
  &:first-letter {
    text-transform: uppercase;
  }
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: $aditional-blue-100 transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $aditional-blue-100 transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent $aditional-blue-100;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent $aditional-blue-100 transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
