/* #region  snackbar styles */
.snackbar-error.mat-snack-bar-container {
  background-color: #fff5f6;
  border: 1px solid #ba484c;

  .mat-simple-snackbar {
    $size-icon: 1.8rem;
    color: #ba484c;
    &::before {
      content: "";
      position: relative;
      background-size: $size-icon $size-icon;
      background-image: url("/assets/img/icon/warning.png");
      width: $size-icon;
      height: $size-icon;
      left: -0.6rem;
    }
    &::after {
      content: "";
      position: absolute;
      background-size: $size-icon $size-icon;
      background-image: url("/assets/img/icon/times.png");
      width: $size-icon;
      height: $size-icon;
      right: 1rem;
      z-index: -1;
    }
  }
}

.mat-simple-snackbar-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  .mat-simple-snackbar {
    color: #155724;
    &::before {
      width: 3.6rem !important;
      background-image: none !important;
    }

    &::after {
      background-image: url("/assets/img/icon/close-circle.svg") !important;
    }
  }
}

.notification.mat-snack-bar-container {
  background-color: $color-dark-mode-bg;
  color: $color-dark-mode-contrast;
  border-radius: 4px;
  margin-right: $width-menu-collapsed;
}

.mat-simple-snackbar-action {
  color: transparent;
}
.mat-simple-snackbar-action button {
  background: transparent;
  color: transparent;
}
/* #endregion */

::ng-deep .mat-form-field-flex {
  padding: 0 0 0 0.7rem;
  align-items: center;
  ::placeholder {
    font-size: $p-font-size !important;
  }
}

.mat-dialog-actions {
  min-height: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 1rem !important;

  button .icon {
    width: 2.3rem;
    height: 1rem;
  }
}

.mat-form-field {
  width: 100%;
}
.mat-form-field-label {
  color: $color-common !important;
}

.mat-form-field-infix {
  padding: 0.5em 0 !important;
  border-top-width: 0.4em;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0rem !important;
  }

  .mat-form-field-outline {
    color: white; // This is the border
    background-color: white;
    border-radius: 5px; /** This style goes from .mat-form-field-appearance-outline .mat-form-field-outline-start **/
  }

  .mat-form-field-outline-thick {
    color: $color-gray-2; // This style is when the user focus the input
  }
  .mat-form-field-outline-start,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap {
    border-color: white !important;
  }
}

.mat-checkbox-layout {
  white-space: normal !important;
}
.mat-checkbox-label {
  font-size: $p-font-size;
  font-family: $font-family-regular;
  display: block;
  line-height: 1.4em;
  text-transform: capitalize;
}
.mat-checkbox-checked .mat-checkbox-background {
  background-color: $color-primary;
}
.mat-checkbox-checked .mat-checkbox-ripple .mat-ripple-element {
  background-color: $color-primary !important;
}
.mat-checkbox-checked .mat-checkbox-label {
  color: $color-primary !important;
  font-family: $font-family-medium;
}

.mat-pseudo-checkbox-checked {
  background-color: $color-secondary !important;

  &:after {
    color: $color-secondary-contrast;
  }
}

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"]
  circle {
  stroke: $color-common;
}
