// MO Colors New Desing
// Neutral
$neutral: #fff;
$neutral-10: #fafafa;
$neutral-20: #f4f6f9;
$neutral-30: #e2e8f0;
$neutral-40: #cbd4e1;
$neutral-50: #94a3b8;
$neutral-60: #64748b;
$neutral-70: #475569;
$neutral-80: #283652;
$neutral-90: #1e2a3b;
$neutral-100: #0f1a2a;

// Success
$success-10: #e6f7f7; // #E5F8F8
$success-20: #a6e6de;
$success-40: #71ddb4;
$success-60: #26d07c;
$success-80: #166e33;
$success-100: #0b2f0a;

// Info
$info-10: #e0effc;
$info-20: #92c8f7;
$info-40: #53a8f2;
$info-60: #0080ed;
$info-80: #00427e;
$info-100: #001c36;

// Warning
$warning-5: #fffae0;
$warning-10: #fffae0;
$warning-20: #ffe992;
$warning-40: #ffc853;
$warning-60: #ff9f00;
$warning-80: #874700;
$warning-100: #3b1400;

// Error
$error-10: #fde8ea;
$error-20: #faa2aa;
$error-40: #f7717e;
$error-60: #ff4759;
$error-80: #8f222b;
$error-100: #3a0408;

// Main Indigo
$main-indigo-10: #e8ebf8;
$main-indigo-20: #b1b9e7;
$main-indigo-40: #8390d9;
$main-indigo-60: #485cc7;
$main-indigo-80: #252f6a;
$main-indigo-100: #0f142e;

// Main Dark
$main-dark-10: #e9e9ec;
$main-dark-20: #aaabb7;
$main-dark-40: #707386;
$main-dark-60: #2e334e;
$main-dark-80: #181928;
$main-dark-100: #0c0c14;

// Main Bay of Many
$main-bay-of-many-10: #e4eaef;
$main-bay-of-many-20: #a3b6c9;
$main-bay-of-many-40: #6e8ca9;
$main-bay-of-many-60: #285780;
$main-bay-of-many-80: #152d43;
$main-bay-of-many-100: #09131d;

// Main Coral
$main-coral-10: #e0eff7;
$main-coral-20: #92d0e3;
$main-coral-40: #53c6d2;
$main-coral-60: #00b7bd;
$main-coral-80: #00615b;
$main-coral-100: #002a20;

// Aditional Blue
$aditional-blue-10: #e0f0fa;
$aditional-blue-20: #92ccee;
$aditional-blue-40: #53afe4;
$aditional-blue-60: #008ad8;
$aditional-blue-80: #004872;
$aditional-blue-100: #001f31;

// Aditional Yellow
$aditional-yellow-10: #f7fdd6;
$aditional-yellow-20: #e0fa52;
$aditional-yellow-40: #d1f704;
$aditional-yellow-60: #879601;
$aditional-yellow-80: #4a4a00;
$aditional-yellow-100: #302a00;

// Aditional Gray
$aditional-gray-10: #f8f8f8;
$aditional-gray-20: #bababa;
$aditional-gray-40: #7d7d7c;
$aditional-gray-60: #3c3c3b;
$aditional-gray-80: #222222;
$aditional-gray-100: #070707;

// Caribbean Green
$caribbean-green-10: #e0f9ee;
$caribbean-green-20: #92eac5;
$caribbean-green-40: #53dea3;
$caribbean-green-60: #00cf78;
$caribbean-green-80: #006e40;
$caribbean-green-100: #002f1c;

// Bluette
$bluette-10: #eceafd;
$bluette-20: #bab7fa;
$bluette-40: #8b94f8;
$bluette-60: #4860f5;
$bluette-80: #253b8b;
$bluette-100: #0c2040;
/**
*
*
*
*
*
*
*
*
*
*
*
*
*
/

/* ****************************************************
 * MO Colors
 * ****************************************************/

$green: #2ec973;
$yellow: #ffa300;
$blue-dark: #283552;
$blue: #365e80;
$blue-light: #1dc1c1;
$contrast: white;

/* ****************************************************
 * Principal colors, usually for theme the app
 * ****************************************************/

$blue-gradient: radial-gradient(
  circle,
  rgba(0, 129, 241, 1) 20%,
  rgba(0, 54, 78, 1) 68%
);

// Color for general text, paragraphs, etc.
$color-common: #00364e;
$color-common-contrast: white;

// Color primary, usually for navs, headers, buttons
$color-primary: #00b9bf;
$color-primary-contrast: #fff;

// Color secondary, usually to contrast or join with primary color
$color-secondary: #005682;
$color-secondary-contrast: #fff;

// Color tertiary, usually to emphasis some buttons or actions
$color-tertiary: #00364e;
$color-tertiary-contrast: white;

$color-highlight: #f4f6f9;
$color-dark-mode-highlight: #262932;
$color-dark-mode-bg: #1f232e;
$color-dark-mode-contrast: #e0e0e0;
$color-button-gray: #c7c7c7;

$bg-common: #1f233e;
$bg-light: #fafafa;
$vertical-gradient: linear-gradient(
  135deg,
  $color-primary 0%,
  $color-common 50%
);

/* ****************************************************
 * State colors, usually to represent status, or actions
 * ****************************************************/

$color-active: #00d477;
$color-active-contrast: white;

$color-warning: #ffa200;
$color-warning-contrast: white;

$color-info: #1d9ac1;
$color-info-contrast: white;

$color-alert: #ff4759;
$color-alert-contrast: white;

$color-default: #fafafa;
$color-default-contrast: #283552;

$color-disabled: #e5e5e5;
$color-disabled-contrast: white;

/* ****************************************************
 * Gray tones to use in shadows, borders, lines, etc
 * ****************************************************/

$color-gray-1: #333;

$color-gray-2: #4a4a4a;

$color-gray-3: #828282;

$color-gray-4: #e0e0e0;

$color-gray-5: #f2f2f2;

$color-gray-6: #f8f8f8;

$color-gray-7: #f6f6f6;

$colors: (
  primary: (
    color: $main-indigo-60,
    contrast: $color-primary-contrast,
  ),
  primary-gradient: (
    color:
      linear-gradient(
        -8deg,
        $color-primary 50%,
        darken($color-secondary, 25) 50%
      ),
    contrast: $color-primary-contrast,
    text-class: false,
  ),
  secondary: (
    color: $color-secondary,
    contrast: $color-secondary-contrast,
  ),
  tertiary: (
    color: $color-tertiary,
    contrast: $color-tertiary-contrast,
  ),
  alert: (
    color: $color-alert,
    contrast: $color-alert-contrast,
  ),
  default: (
    color: $color-default,
    contrast: $color-default-contrast,
  ),
  disabled: (
    color: $color-disabled,
    contrast: $color-disabled-contrast,
    text-class: false,
  ),
  common: (
    color: $color-common,
    contrast: $color-common-contrast,
    text-class: false,
  ),
);
