@mixin inputControlBase() {
  display: block;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: $neutral-80;
  background-color: $neutral-20;
  background-clip: padding-box;
  border-radius: 6px;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: $roboto-regular;
  border: 1px solid $neutral-10;
  position: relative;
  padding: 12px 16px;

  &.size-lg {
    padding: 14px 18px;
    height: 56px;
    font-size: 16px;
  }
  &.size-md {
    padding: 10px 14px;
    height: 46px;
    font-size: 13px;
  }
  &.size-sm {
    padding: 5px 12px;
    font-size: 12px;
    height: 28px;
  }
  &.light {
    background-color: white;
    border: 1px solid $neutral-30;
    &:hover {
      background-color: $neutral-20;
    }
  }
  &.primary {
    background-color: $success-10;
    border: 1px solid $success-60;
    i,
    .item-option {
      color: $success-80;
      font-family: $roboto-medium;
    }
    &:hover {
      background-color: $success-20;
    }
  }
  &.outline-primary {
    background-color: white;
    border: 1px solid $caribbean-green-60;
    &:hover {
      background-color: $caribbean-green-10;
    }
  }
  &:hover {
    background-color: $neutral-20;
  }
  &.error-control {
    border: 1px solid $error-60;
  }
  &:focus {
    border: 2px solid $caribbean-green-60;
    background-color: $neutral-20;
  }
  &.disabled {
    opacity: 0.5;
  }
}

@mixin placeholder($font-size, $color-base, $color-hover) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-base;
    font-size: $font-size;
    font-family: $roboto-regular;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-base;
    font-size: $font-size;
    font-family: $roboto-regular;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-base;
    font-size: $font-size;
    font-family: $roboto-regular;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-base;
    font-size: $font-size;
    font-family: $roboto-regular;
  }
  :hover::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-hover;
  }
  :hover::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-hover;
  }
  :hover:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-hover;
  }
  :hover:-moz-placeholder {
    /* Firefox 18- */
    color: $color-hover;
  }
}
