.status {
  padding: 4px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  p,
  label {
    padding: 0;
    margin: 0;
    font-family: $font-family-bold;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &.accepted,
  &.successful,
  &.completed,
  &.active {
    background-color: $success-10;
    color: $success-80;
  }
  &.archived,
  &.pending {
    background-color: $warning-20;
    color: $warning-80;
  }
  &.rejected,
  &.unrecovery,
  &.inactive,
  &.canceled {
    background-color: $error-10;
    color: $error-60;
  }
  &.dispute,
  &.reversed,
  &.paid {
    background-color: $main-indigo-10;
    color: $main-indigo-60;
  }
  &.frozen {
    background-color: $main-coral-10;
    color: $main-coral-60;
  }
  &.unregistered,
  &.unsigned,
  &.closed {
    background-color: $main-bay-of-many-10;
    color: $main-coral-100;
  }
}

.customer-status {
  @extend .status;
  &.Active {
    background-color: $caribbean-green-20;
    color: $caribbean-green-80;
  }
}
