@import "../variables/font";

@mixin text($font-size, $font-family) {
  font-family: $font-family;
  font-size: $font-size;
}

$title-size: (
  "48": 48px,
  "38": 38px,
  "28": 28px,
  "20": 20px,
);

$subtitles-size: (
  "20": 20px,
  "18": 18px,
);

$body-size: (
  "16": 16px,
  "14": 14px,
);

$button-size: (
  20: 20px,
  18: 18px,
  14: 14px,
);

$caption-size: (
  10: 10px,
  12: 12px,
);

@each $name, $size in $title-size {
  .title-#{$name} {
    &-bold {
      @include text($size, $poppins-bold);
    }

    &-semi-bold {
      @include text($size, $poppins-regular);
      font-weight: 600;
    }
  }
}

@each $name, $size in $subtitles-size {
  .subtitle-#{$name} {
    &-bold {
      @include text($size, $poppins-bold);
    }

    &-medium {
      @include text($size, $poppins-regular);
      font-weight: 500;
    }

    &-semi-bold {
      @include text($size, $poppins-regular);
      font-weight: 600;
    }

    &-regular {
      @include text($size, $poppins-regular);
      font-weight: normal;
    }
  }
}

@each $name, $size in $body-size {
  .body-#{$name} {
    &-bold {
      @include text($size, $roboto-bold);
    }

    &-medium {
      @include text($size, $roboto-regular);
      font-weight: 500;
      &-underline {
        text-decoration-line: underline;
      }
    }

    &-regular {
      @include text($size, $roboto-regular);
      font-weight: normal;
      &-underline {
        text-decoration-line: underline;
      }
    }
  }
}

@each $name, $size in $caption-size {
  .caption-#{$name} {
    &-bold {
      @include text($size, $roboto-bold);
      &-underline {
        text-decoration-line: underline;
      }
    }

    &-regular {
      @include text($size, $roboto-regular);
      font-weight: normal;
      &-underline {
        text-decoration-line: underline;
      }
    }
  }
}

@each $name, $size in $body-size {
  .button-#{$name} {
    &-bold {
      @include text($size, $poppins-bold);
    }
  }
}

/*
*
*
*
*  Old 
*   Designs
*
*
*
*
*
*/

.no-wrap {
  white-space: nowrap;
}
.no-hyphen {
  hyphens: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-color-common {
  color: $color-common !important;
}

.text-color-gray {
  color: $color-gray-3;
}

.text-color-primary {
  color: $color-primary !important;
}

.text-color-secondary {
  color: $color-secondary !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-with-decoration {
  position: relative;
  background-image: url(/assets/img/resources/text-bg-decoration.png);
  background-size: contain;
  background-repeat: no-repeat;

  &::before {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
}
