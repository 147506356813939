.mo-table-container {
  position: relative;
  overflow: auto;
  @include container;
}

.mo-table {
  @include columns;
  position: relative;
  margin: 0.5rem 0;
  overflow-x: scroll;
  scroll-behavior: smooth;
  min-width: 100%;

  .mo-header,
  .mo-row {
    padding: 12px 0;
    margin: 0;
    text-align: center;
  }

  .mo-header {
    label {
      font-family: $roboto-bold;
      white-space: nowrap;
    }
    .mo-row {
      &.template-default {
        background-color: $neutral-20;
        border-radius: 5px;
        border: none;
      }
      &.template-alternative {
        background-color: $neutral-40;
        border-radius: 5px;
        border: none;
      }
      &.template-white {
        background-color: $neutral;
        border-radius: 5px;
        border: none;
      }
    }

    .sort-icon {
      margin-left: 5px;
    }
  }

  .mo-row {
    box-sizing: border-box;
    display: flex;

    &.template-default {
      background-color: $neutral;
      border-bottom: 1px solid $neutral-40;
      .table-title {
        background-color: $neutral-20;
      }
      .table-content {
        background-color: $neutral;
      }
    }
    &.template-alternative {
      background-color: $neutral;
      border-bottom: 1px solid $neutral-40;
      .table-title {
        background-color: $neutral-40;
      }
      .table-content {
        background-color: $neutral;
      }
    }
    &.template-white {
      background-color: $neutral;
      border-bottom: 1px solid $neutral-40;
      .table-title {
        background-color: $neutral;
      }
      .table-content {
        background-color: $neutral;
      }
    }
  }

  .mo-col {
    word-break: break-word;
    &.fixed-min-width {
      min-width: 150px;
    }
    &.sticky-col {
      position: sticky;
      left: 0px;
    }
  }

  .mo-cell {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: $label-font-size;
      color: $aditional-blue-60;
      padding: 0;
    }
  }
}
